import React from 'react'
import { Box } from '../../Box'
import { Text } from '../../Text'
import { transparentize } from 'polished'
import theme from '../../../theme'

interface TagProps {
  label: string
  color?: 'dark' | 'darkBlue' | 'red' | 'green' | 'blue' | 'purple'
  light?: boolean
}

function Tag({ color, label, light }: TagProps): JSX.Element {
  return (
    <Box
      display={'inline-block'}
      px={2}
      py={1}
      borderRadius={'default'}
      backgroundColor={light ? transparentize(0.9, theme.colors[color ?? 'dark']) : color}
    >
      <Text color={light ? color : 'white'} type={'small'} fontWeight={'ultraBold'} textTransform={'capitalize'}>
        {label}
      </Text>
    </Box>
  )
}

export default Tag

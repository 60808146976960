import { createGlobalStyle } from 'styled-components'
import theme from './theme'
import hubspotForms from './hubspotForms'

const GlobalStyle = createGlobalStyle`
  :root {
    --banner-height: 0px;
  }
  
  *, *::before, *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
  }
  html, body {
    height: 100%;
    background-color: #FFF;
  }
  html {
    font-size: 62.5%;
  }
  body {
    font-size: 1.6rem;
    font-family: ${theme.fonts.body};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  input, button, textarea, select {
    font: inherit;
  }
  
  input {
    appearance: none;
    padding: 13px 16px;
    border: 1px solid ${theme.colors.dark};
    border-radius: ${theme.radii.small}px;
    transition: all 0.25s ease-in-out;
    outline: none;
    
    &:focus {
      border-color: ${theme.colors.darkBlue};
    }
  }
  
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  a {
    text-decoration: none;
    display: inline-block;
    
    &:visited {
      color: inherit;
    }
  }
  
  mark {
    background-color: ${({ theme }) => theme.colors.yellow};
  }
  
  main > section {
    scroll-margin-top: calc(60px + var(--banner-height));
  }
  
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .noscrollBar::-webkit-scrollbar {
    display: none;
  }
  
  .noscrollBar {
    scrollbar-color: transparent transparent;
  }
  
  .complianceMessage {
    font-size: 1.2rem;
    a {
      text-decoration: underline;
      transition: all 0.25s ease-in-out;
      
      &:hover {
        color: ${({ theme }) => theme.colors.darkBlue};
      }
    }
  }
  
  .ModalImagePortal {
    .ReactModal__Overlay {
      background-color: rgba(0, 0, 0, 0.5) !important;
      z-index: 10000;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    .ReactModal__Content {
      inset: 0;
      position: relative !important;
      max-width: calc(100vw - 32px);
      max-height: calc(var(--vh, 1vh) * 90);
      border: none !important;
      border-radius: 8px !important;
      padding: 0 !important;
      background-color: #FFF;
    }
  }
  
  .ModalPortal {
    .Modal__Overlay {
      position: fixed;
      inset: 0;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.25);
      z-index: 9900;
      overflow: hidden;
      transition: opacity 0.5s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Modal__Overlay.ReactModal__Overlay--after-open{
      opacity: 1;
    }
    .Modal__Overlay.ReactModal__Overlay--before-close{
      opacity: 0;
    }
    
    .Modal__Content.ReactModal__Content--after-open{
      transform: translateY(0);
    }
    .Modal__Content.ReactModal__Content--before-close{
      transform: translateY(20px);
    }
    .Modal__Content {
      transform: translateY(20px);
      transition: transform 0.5s ease-in-out;
      border: none;
      border-radius: 0;
      flex: 0 1 764px;
      max-width: calc(100% - 32px);
      height: auto;
      max-height: calc((var(--vh,1vh) * 100) - 72px);
      overflow: auto;
    }
  }

  ${hubspotForms}
`

export default GlobalStyle

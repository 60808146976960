import { STORAGE_LANG_KEY } from './constants'
import { isSSR } from './isSSR'

const storeLocale = (locale: string) => {
  if (!isSSR) {
    localStorage.setItem(STORAGE_LANG_KEY, locale)
  }
}

const getStoredLocale = () => {
  if (!isSSR) {
    return localStorage.getItem(STORAGE_LANG_KEY)
  }
}

const getLocaleName = (locale: string | null) => {
  if (!locale) return ''

  switch (locale) {
    case 'en':
      return 'English'
    case 'fr':
      return 'Français'
    default:
      return locale
  }
}

const checkIsBotUserAgent = () => {
  const agent = navigator.userAgent
  const botUserAgentsArray = ['googlebot', 'bingbot', 'linkedinbot', 'mediapartners-google', 'lighthouse', 'insights']

  let isBotUserAgent = false
  for (let j = 0; j < botUserAgentsArray.length; j++) {
    if (agent.toLowerCase().indexOf(botUserAgentsArray[j].toLowerCase()) !== -1) {
      console.log('botUserAgent found: ' + botUserAgentsArray[j].toLowerCase())
      isBotUserAgent = true
      break
    }
  }

  return isBotUserAgent
}

export { storeLocale, getStoredLocale, getLocaleName, checkIsBotUserAgent }

import React from 'react'
import theme from '../../../theme'

function Search(props: React.SVGProps<SVGSVGElement> & { color?: string; size?: number }): JSX.Element {
  const { color, style, size, ...otherProps } = props
  return (
    <svg
      width={size ? size : 16}
      height={size ? size : 16}
      viewBox={'0 0 16 16'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <g
        opacity={0.5}
        stroke={color ? theme.colors[color] || color : 'currentColor'}
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M7.217 12.435A5.218 5.218 0 107.217 2a5.218 5.218 0 000 10.435zM14 14l-3.093-3.093' />
      </g>
    </svg>
  )
}
export default Search

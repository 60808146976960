import { isSSR } from './isSSR'

export const pushEvent = (event?: string, customParam?: string) => {
  if (!isSSR && !!event) {
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({
      event: event,
      ...(!!customParam && { customParam: customParam }),
    })
  }
}

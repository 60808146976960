import { MarginProps, TypographyProps, ColorProps } from 'styled-system'
import { StyledText } from './Text.styled'

export type TextProps = TypographyProps &
  ColorProps &
  MarginProps & {
    type?: 'large' | 'regular' | 'small' | 'xsmall'
    textTransform?: string
    textDecoration?: string
    whiteSpace?: string
  }

StyledText.defaultProps = {
  fontFamily: 'body',
  color: 'dark',
  fontWeight: 'normal',
  type: 'regular',
}

export default StyledText

import React, { ReactNode } from 'react'
import { Flex } from '../Flex'

const NavButton = ({
  children,
  onClick,
  disabled,
}: {
  children: ReactNode
  disabled?: boolean
  onClick: (e: any) => void
}) => {
  return (
    <Flex
      as={'button'}
      backgroundColor={'transparent'}
      justifyContent={'center'}
      alignItems={'center'}
      width={[40, 40, 48]}
      height={[40, 40, 48]}
      onClick={onClick}
      borderColor={'grey50'}
      borderWidth={'1px'}
      borderStyle={'solid'}
      borderRadius={40}
      disabled={disabled}
      opacity={disabled ? 0.5 : 1}
      clickable={!disabled}
    >
      {children}
    </Flex>
  )
}

export default NavButton

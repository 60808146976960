import styled, { css } from 'styled-components'
import {
  compose,
  position,
  PositionProps,
  space,
  SpaceProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  background,
  BackgroundProps,
  border,
  BorderProps,
  shadow,
  ShadowProps,
  flex,
  FlexProps,
  flexBasis,
  FlexBasisProps,
  flexGrow,
  FlexGrowProps,
  flexShrink,
  FlexShrinkProps,
  textAlign,
  TextAlignProps,
  typography,
  GridTemplateColumnsProps,
  gridTemplateColumns,
  gridTemplateRows,
  GridTemplateRowsProps,
  gridColumnGap,
  GridColumnGapProps,
  gridRowGap,
  GridRowGapProps,
  gridArea,
  GridAreaProps,
  TypographyProps,
  AlignItemsProps,
  alignItems,
} from 'styled-system'

export type BoxProps = PositionProps &
  SpaceProps &
  ColorProps &
  TypographyProps &
  LayoutProps &
  BackgroundProps &
  BorderProps &
  ShadowProps &
  FlexProps &
  FlexBasisProps &
  FlexGrowProps &
  FlexShrinkProps &
  AlignItemsProps &
  GridTemplateRowsProps &
  GridColumnGapProps &
  GridTemplateColumnsProps &
  GridAreaProps &
  GridRowGapProps &
  TextAlignProps & {
    transition?: string
    clickable?: boolean
  }

const Box = styled.div<BoxProps>`
  ${compose(
    position,
    space,
    color,
    typography,
    layout,
    background,
    border,
    shadow,
    flex,
    flexBasis,
    flexGrow,
    flexShrink,
    textAlign,
    alignItems,
    gridTemplateColumns,
    gridTemplateRows,
    gridColumnGap,
    gridRowGap,
    gridArea
  )}

  ${({ transition }) =>
    transition &&
    css`
      transition: ${transition};
    `}

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`

export default Box

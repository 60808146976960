import React from 'react'

function CrossLarge(props: React.SVGProps<SVGSVGElement> & { color?: string; size?: number }): JSX.Element {
  const { color, style, size, ...otherProps } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 24}
      height={size ?? 24}
      viewBox={'0 0 24 24'}
      fill='none'
      {...otherProps}
    >
      <g fillRule='evenodd' clipPath='url(#a)' clipRule='evenodd'>
        <path
          fill='#0E0E0F'
          d='m6.193 2.007 6.275 6.26 6.078-6.063 4.499 4.488-1.001 1h.001l-5.077 5.065 6.235 6.22-1.001 1-4.814 4.804-6.236-6.221-6.078 6.064-4.499-4.49 1.001-.998v-.001l5.076-5.065L.378 7.81l1.001-.998-.001-.001z'
        />
        <path
          fill='#F4726B'
          d='m6.193 3.42 6.275 6.26 6.078-6.064 3.083 3.076-6.078 6.064 6.236 6.221-3.399 3.392-6.236-6.222-6.077 6.064-3.084-3.076L9.07 13.07l-6.275-6.26z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 .894h24v24H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default CrossLarge

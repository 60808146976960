const isEnabled = () =>
  process.env.NODE_ENV === `production` && process.env.GATSBY_INTERCOM_APP_ID && typeof Intercom === `function`

exports.onInitialClientRender = () => {
  if (!isEnabled()) {
    return
  }

  window.Intercom('boot', {
    app_id: process.env.GATSBY_INTERCOM_APP_ID,
    api_base: 'https://api-iam.intercom.io',
  })
}

exports.onRouteUpdate = function ({ location }) {
  if (!isEnabled()) {
    return
  }

  window.Intercom('update')
}

import React, { createContext, ReactNode, useContext, useState } from 'react'
import Modal from 'react-modal'
import { Box, Flex, Heading, Text, Image, Icons } from '../atoms'
import { HubspotFormProps, ModalProps, TypeformFormProps } from '../types/blocks'
import { isSSR } from '../utils/isSSR'
import HubspotForm from '../components/commons/HubspotForm'
import TypeformForm from '../components/commons/TypeformForm'

interface ModalContextProps {
  openModal: (content: ModalProps) => void
  closeModal: () => void
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined)

export default ModalContext

interface ModalContextProviderProps {
  children: ReactNode
}

Modal.setAppElement(!isSSR ? window.document.getElementById('page-wrapper') ?? '#___gatsby' : '#___gatsby')

const ModalContextProvider = ({ children }: ModalContextProviderProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<ModalProps | null>(null)

  const onOpenModal = (content: any) => {
    setModalContent(content)
    setModalOpen(true)
  }

  const onCloseModal = () => {
    setModalOpen(false)
    setTimeout(() => {
      setModalContent(null)
    }, 500)
  }

  return (
    <ModalContext.Provider
      value={{
        openModal: onOpenModal,
        closeModal: onCloseModal,
      }}
    >
      {children}

      <Modal
        portalClassName={'ModalPortal'}
        overlayClassName={'Modal__Overlay'}
        className={'Modal__Content'}
        closeTimeoutMS={550}
        parentSelector={() => document.querySelector('#page-wrapper') ?? document.body}
        isOpen={modalOpen}
        onRequestClose={onCloseModal}
      >
        <Box position={'relative'} p={[6, 7, 8]} backgroundColor={'white'} width={'100%'}>
          <Box position={'absolute'} top={[12, 16]} right={[12, 16]} clickable>
            <Icons.Close color='grey30' onClick={onCloseModal} />
          </Box>

          {modalContent && (
            <Box>
              <Flex
                alignItems={['stretch', 'center']}
                flexDirection={['column-reverse', 'row']}
                flexWrap={['wrap', 'nowrap']}
              >
                <Box flex={['0 0 100%', '1', '1']}>
                  {modalContent.preHeadline && (
                    <Box mb={3}>
                      <Text type={'regular'} fontWeight={'bold'}>
                        {modalContent.preHeadline}
                      </Text>
                    </Box>
                  )}
                  <Heading as={'h2'} type={'heading3'}>
                    {modalContent.headline}
                  </Heading>
                  {modalContent.subHeadline && (
                    <Box mt={2}>
                      <Text type={'regular'}>{modalContent.subHeadline}</Text>
                    </Box>
                  )}
                </Box>
                {modalContent.asset && (
                  <Box
                    mb={[4, 0]}
                    ml={[0, 6]}
                    flex={['0 0 100%', '0 0 120px', '0 0 230px']}
                    backgroundColor={'darkBlue'}
                    borderRadius={8}
                    overflow={'hidden'}
                  >
                    <Image
                      alt={modalContent.asset.alt ?? ''}
                      image={modalContent.asset.gatsbyImageData}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </Box>
                )}
              </Flex>

              <Box mt={6}>
                {modalContent.form.model.apiKey === 'hubspot_form' && (
                  <HubspotForm {...(modalContent.form as HubspotFormProps)} onFormSubmitted={() => onCloseModal()} />
                )}
                {modalContent.form.model.apiKey === 'typeform_form' && (
                  <TypeformForm format={'horizontal'} {...(modalContent.form as TypeformFormProps)} />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const modalContext = useContext(ModalContext)
  if (!modalContext) {
    throw new Error('useModal must be used within a ModalContextProvider')
  }
  return modalContext
}

export { ModalContextProvider }

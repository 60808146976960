import React from 'react'

const PlayButton: React.FC = () => {
  return (
    <svg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='96' height='96' rx='8' fill='#706BF4' />
      <path
        d='M62.5 47.134C63.1667 47.5189 63.1667 48.4811 62.5 48.866L41.5 60.9904C40.8333 61.3753 40 60.8942 40 60.1244L40 35.8756C40 35.1058 40.8333 34.6247 41.5 35.0096L62.5 47.134Z'
        fill='white'
      />
    </svg>
  )
}

export default PlayButton

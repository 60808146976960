import { MarginProps, TypographyProps, ColorProps } from 'styled-system'

import { StyledHeading } from './Heading.styled'

export type HeadingProps = TypographyProps &
  ColorProps &
  MarginProps & {
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    type?: 'display' | 'heading1' | 'heading2' | 'heading3' | 'heading4' | 'heading5' | 'heading6'
    whiteSpace?: string
  }

StyledHeading.defaultProps = {
  as: 'h1',
  type: 'heading1',
}

export default StyledHeading

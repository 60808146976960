import React, { useEffect } from 'react'
import { graphql, navigate, PageProps, useStaticQuery } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'
import { renderMetaTags, TitleMetaLinkTag } from 'react-datocms'
// Theme
import theme, { GlobalStyle } from '../theme'
// Utils
import { getStoredLocale, checkIsBotUserAgent } from '../utils/locale'
// Hooks
import useWindowSize from '../hooks/useWindowSize'
//
import { Box } from '../atoms'
import { PageContextProps } from '../types/pages'
import { CookiesProvider } from 'react-cookie'
import { ModalContextProvider } from '../contexts/ModalContext'

const getRedirectLanguage = () => {
  if (typeof navigator === 'undefined' || checkIsBotUserAgent()) {
    return null
  }
  let lang
  const preferredLocale = getStoredLocale()
  if (preferredLocale) {
    lang = preferredLocale
  } else {
    lang = navigator && navigator.language && navigator.language.split('-')[0]
  }
  if (!lang) return null
  switch (lang) {
    case 'fr':
      return 'fr'
    case 'en':
    default:
      return 'en'
  }
}

const LayoutWrapper: React.FC<PageProps<any, PageContextProps>> = ({ children, pageContext }) => {
  const metaTags = useStaticQuery(graphql`
    query FaviconQuery {
      site: datoCmsSite {
        favicon: faviconMetaTags {
          tags
        }
      }
    }
  `)

  const size = useWindowSize()

  const tags = metaTags.site.favicon.tags.map((t: Omit<TitleMetaLinkTag, 'tag'> & { tagName: string }) => {
    const { tagName, ...rest } = t
    return { ...rest, tag: tagName }
  })

  useEffect(() => {
    const lang = getRedirectLanguage()

    if (lang && lang !== pageContext.locale && pageContext.slugLocales) {
      let slugToGo
      const slugForLocale = pageContext.slugLocales.find((slugLocale) => slugLocale.locale === lang)
      if (slugForLocale) {
        if (slugForLocale.value === '/') {
          slugToGo = lang === 'en' ? `/` : `/${lang}`
        } else {
          slugToGo = lang === 'en' ? `/${slugForLocale.value}` : `/${lang}/${slugForLocale.value}`
        }
      } else {
        slugToGo = lang === 'en' ? `/` : `/${lang}`
      }
      void navigate(slugToGo, { replace: true })
    }
  }, [])

  // Prevent bad height on mobile
  useEffect(() => {
    let vh = size.height! * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [size])

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <Helmet>{renderMetaTags([...tags])}</Helmet>

        <GlobalStyle />
        <ModalContextProvider>
          <Box id={'page-wrapper'} position={'relative'}>
            {children}
            <Box
              position={'absolute'}
              top={0}
              left={0}
              width={'100%'}
              height={'100%'}
              zIndex={9999}
              backgroundImage={'url(/assets/images/noise.png)'}
              style={{ pointerEvents: 'none', mixBlendMode: 'overlay', willChange: 'mix-blend-mode' }}
            />
          </Box>
        </ModalContextProvider>
      </ThemeProvider>
    </CookiesProvider>
  )
}

export default LayoutWrapper

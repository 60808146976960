import React from 'react'
import theme from '../../../theme'

function Minus(props: React.SVGProps<SVGSVGElement> & { color?: string; size?: number }): JSX.Element {
  const { color, style, size, ...otherProps } = props
  return (
    <svg
      width={size ? size : 22}
      height={size ? size : 22}
      viewBox={'0 0 22 22'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <path
        d='M21 11H1'
        stroke={color ? theme.colors[color] || color : 'currentColor'}
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default Minus

import React from 'react'
import theme from '../../../theme'

function Facebook(props: React.SVGProps<SVGSVGElement> & { color?: string; size?: number }): JSX.Element {
  const { color, style, size, ...otherProps } = props
  return (
    <svg
      width={size ? size : 24}
      height={size ? size : 24}
      viewBox={'0 0 24 24'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <path
        d='M17.64 7.17a.5.5 0 00-.37-.17H13.5V5.59c0-.28.06-.6.51-.6h3a.44.44 0 00.35-.15.5.5 0 00.14-.34v-4A.5.5 0 0017 0h-4.33C7.87 0 7.5 4.1 7.5 5.35V7H5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h2.5v11.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V12h3.35a.5.5 0 00.5-.45l.42-4a.5.5 0 00-.13-.38z'
        fill={color ? theme.colors[color] || color : 'currentColor'}
      />
    </svg>
  )
}
export default Facebook

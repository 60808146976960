import { css } from 'styled-components'
import { rgba } from 'polished'
import theme from './theme'

const hubspotForms = css`
  .hubspot-form-container {
    .hs_cos_wrapper.form-title {
      display: none;
    }

    .grecaptcha-badge {
      width: auto !important;
      height: auto !important;
      box-shadow: none !important;
    }

    .center-the-form form,
    .center-the-form form input {
      text-align: center;
    }

    .fn-date-picker .pika-button {
      text-align: center !important;
    }

    .hs-form {
      max-width: 780px;
      margin: auto;

      // GLOBAL
      ul {
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;

        li {
          margin-top: 4px;
          margin-bottom: 4px !important;
          width: 100%;
        }
      }

      fieldset {
        max-width: 100% !important;
        margin-bottom: 0 !important;

        &.form-columns-2 .hs-form-field,
        &.form-columns-3 .hs-form-field,
        &.form-columns-4 .hs-form-field {
          float: left;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;

          @media screen and (max-width: 767px) {
            width: 100% !important;
          }
        }

        &.form-columns-1 .hs-form-field {
          width: 100% !important;
        }

        &.form-columns-2 .hs-form-field {
          width: 50%;
        }

        &.form-columns-3 .hs-form-field {
          width: 33.333% !important;
        }

        &.form-columns-4 .hs-form-field {
          width: 25% !important;
        }
      }

      .hs-form-field {
        padding: 0 10px;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin-bottom: 24px;

        > label,
        > legend.hs-field-desc {
          width: 100% !important;
          text-align: left !important;
          padding: 0 !important;
          display: inline-block;
          margin-bottom: 8px;
          color: ${theme.colors.dark} !important;
          font-size: 16px !important;
          line-height: 1.5em;
        }

        > label {
          font-weight: 800;
        }

        > legend.hs-field-desc {
          font-weight: 400;
        }
      }

      // LAYOUT
      @media screen and (max-width: 767px) {
        .hubspot-form-container .hs-form .form-columns-2 > div.hs-form-field,
        .hubspot-form-container .hs-form .form-columns-3 > div.hs-form-field,
        .hubspot-form-container .hs-form .form-columns-4 > div.hs-form-field {
          width: 100% !important;
        }
      }

      // INPUTS

      input[type='date'],
      input[type='datetime'],
      input[type='datetime-local'],
      input[type='email'],
      input[type='month'],
      input[type='number'],
      input[type='password'],
      input[type='range'],
      input[type='search'],
      input[type='tel'],
      input[type='text'],
      input[type='time'],
      input[type='url'],
      input[type='week'],
      textarea,
      select {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 100% !important;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        text-align: left !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 1.5em;
        color: ${theme.colors.dark} !important;
        background-color: ${theme.colors.white} !important;
        padding: 11px 16px;
        border-style: solid !important;
        border-width: 1px !important;
        border-color: ${theme.colors.dark} !important;
        border-radius: 8px !important;
        -moz-border-radius: 8px !important;
        -webkit-border-radius: 8px !important;
        height: auto !important;
        transition: all 0.25s ease-in-out;

        &:focus {
          outline: none;
          border-color: ${theme.colors.darkBlue} !important;
        }

        &.invalid,
        &.error {
          border-color: ${theme.colors.red} !important;
        }

        &::placeholder,
        &.is-placeholder {
          color: rgba(12, 10, 48, 0.64) !important;
          font-weight: 400 !important;
          text-align: left !important;
        }
      }

      input[type='checkbox'],
      input[type='radio'] {
        width: auto;
        margin-right: 12px;
      }

      .hs-form-checkbox {
        margin: 24px 0 !important;

        label {
          display: flex;
          align-items: center;
        }

        input[type='checkbox'] {
          width: 0;
          height: 0;
          opacity: 0;
          margin: 0;
          font-size: 0;
          line-height: 0;
        }

        span {
          display: inline-flex;
          flex: 0 0 100%;
          color: ${theme.colors.dark};
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          transition: color 0.25s ease-in-out;

          &:before {
            content: '';
            box-sizing: border-box;
            display: block;
            margin-right: 12px;
            margin-top: -4px;
            flex: 0 0 32px;
            width: 18px;
            height: 18px;
            border: 1px solid ${theme.colors.dark};
            background-color: transparent;
            color: ${theme.colors.white};
            transition: all 0.25s ease-in-out;
          }
        }

        input[type='checkbox']:checked + span {
          color: ${theme.colors.dark};

          &:before {
            content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABRSURBVHgB3ZBBDQAgDAMnAQlIwCE4QALSkICEUX4Nr43wIDTpZ+0l20T+k6pmuJ1ASx2OdyAM0x5YoAAPLpjXQ1ioWF03Eex7BME+iOAgz2kCRX2O4DjGJvwAAAAASUVORK5CYII=');
            border-color: ${theme.colors.darkBlue};
            background-color: ${theme.colors.darkBlue};
          }
        }
        input[type='checkbox']:disabled + span {
          color: ${rgba(theme.colors.dark, 0.4)};

          &:before {
            border-color: transparent;
            background-color: ${rgba(theme.colors.dark, 0.08)};
          }
        }

        &:first-child {
          margin-top: 16px !important;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }

        &:hover {
          span {
            color: ${theme.colors.dark};
          }
        }
        &:hover input[type='checkbox']:not([disabled]) + span {
          cursor: pointer;
        }
      }

      .hs-form-radio {
        margin: 12px 0 !important;

        label {
          display: flex;
          align-items: center;
        }

        input[type='radio'] {
          width: 0;
          height: 0;
          opacity: 0;
          margin: 0;
          font-size: 0;
          line-height: 0;
        }

        span {
          position: relative;
          display: flex;
          flex: 0 0 100%;
          color: ${theme.colors.dark};
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          transition: all 0.25s ease-in-out;

          &:before {
            content: '';
            box-sizing: border-box;
            display: block;
            margin-right: 12px;
            flex: 0 0 24px;
            width: 18px;
            height: 18px;
            border: 1px solid ${theme.colors.dark};
            border-radius: 12px;
            background-color: transparent;
            color: ${theme.colors.white};
            transition: all 0.25s ease-in-out;
          }

          &:after {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 7px;
            position: absolute;
            top: 5px;
            left: 5px;
            background-color: ${theme.colors.white};
            transition: all 0.25s ease-in-out;
          }
        }

        input[type='radio']:checked + span {
          color: ${theme.colors.darkBlue};
          font-weight: 400;

          &:before {
            border-color: ${theme.colors.darkBlue};
          }

          &:after {
            background-color: ${theme.colors.darkBlue};
          }
        }
        input[type='radio']:disabled + span {
          color: ${rgba(theme.colors.dark, 0.4)};

          &:before {
            border-color: transparent;
            background-color: ${rgba(theme.colors.dark, 0.08)};
          }
        }

        &:first-child {
          margin-top: 16px !important;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }

        &:hover {
          span {
            color: ${theme.colors.dark};
          }
        }
        &:hover input[type='radio']:not([disabled]) + span {
          cursor: pointer;
        }
      }

      textarea.hs-input {
        min-height: 200px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
      }

      .hs-fieldtype-select .input {
        position: relative;

        &:after {
          content: '';
          pointer-events: none;
          position: absolute;
          top: 50%;
          right: 12px;
          width: 24px;
          height: 24px;
          transform: translateY(-50%);
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACfSURBVHgBtU/tCcMgED3jH8EPHCEbtG7QETpCNusK2aAjNBu0IxREEEHtHaQlCAn50Tx4IHfv3vMBHA1mrb3mnMF7P24JvzowxozIqpS6rIlpRxrkjRJsKeVBJiklF2N8Nc79vH93Xed+Q7x+EoUQ/VLcztmGEyXf22S2jJdSnjnndDQxRK31hEVdCGFa6wda62EuWOkNe4DfG3aL/44PKntFJLwcuEcAAAAASUVORK5CYII=')
            no-repeat center transparent;
        }

        select {
          padding-right: 40px !important;
        }
      }

      .hs-richtext {
        width: 100% !important;
        padding: 16px 24px !important;
        color: ${theme.colors.dark} !important;
        font-weight: 400 !important;
        font-size: 16px !important;
      }

      // ERRORS
      .hs-form-field .hs-error-msgs {
        margin-top: 6px;

        li {
          margin-bottom: 0 !important;
          float: none;
          width: 100%;
          overflow: hidden;
        }

        label,
        label span {
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 1.2em;
          margin-bottom: 0 !important;
          color: ${theme.colors.red} !important;
        }
      }

      // BUTTONS
      button,
      input[type='button'],
      input[type='reset'],
      input[type='submit'] {
        width: auto;
        -webkit-appearance: none;
      }

      input[type='reset'],
      input[type='submit'] {
        font-weight: 800 !important;
        font-size: 16px !important;
        line-height: 1.5em;
        border: none;
        border-radius: 75px !important;
        -moz-border-radius: 75px !important;
        -webkit-border-radius: 75px !important;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        padding: 12px 24px;
        margin-top: 0;
        transition: all 0.25s ease-in-out;

        &:hover {
          cursor: pointer;
        }
      }

      input[type='reset'] {
        background: transparent;
        border: 2px solid ${theme.colors.dark};
        color: ${theme.colors.dark};

        &:hover {
          background: ${rgba(theme.colors.dark, 0.05)};
        }
      }

      input[type='button'],
      input[type='submit'] {
        color: ${theme.colors.white};
        background: ${theme.colors.darkBlue};

        &:hover {
          background: ${theme.colors.darkBlueShade};
          cursor: pointer;
        }
      }

      .hs-submit {
        display: inline-block;
        width: 100%;
        padding: 0 10px;

        .actions {
          width: 100% !important;
          float: left !important;
          clear: both !important;
          text-align: left !important;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }

    .submitted-message {
      text-align: center;
      line-height: 1.4;
      font-weight: 800;
      font-size: 18px;
      padding: 24px;
      border-radius: 8px;
      color: ${theme.colors.white};
      background-color: ${theme.colors.green};
    }

    // FOR NEWSLETTER
    &--newsletter {
      .hs-form {
        display: flex !important;

        input[type='date'],
        input[type='datetime'],
        input[type='datetime-local'],
        input[type='email'],
        input[type='month'],
        input[type='number'],
        input[type='password'],
        input[type='range'],
        input[type='search'],
        input[type='tel'],
        input[type='text'],
        input[type='time'],
        input[type='url'],
        input[type='week'],
        textarea,
        select {
          border-radius: 8px !important;
          background-color: transparent !important;
        }

        .hs-email {
          flex: 1;
          margin-bottom: 0 !important;

          > label {
            display: none;
          }
        }

        .hs_error_rollup {
          display: none !important;
        }

        .hs-submit {
          flex: 0 0 200px;
        }

        @media screen and (max-width: 480px) {
          display: block !important;

          .hs-email {
            margin-bottom: 16px !important;
          }

          .hs-form-field,
          .hs-submit {
            padding: 0 0;
          }

          input[type='button'],
          input[type='submit'] {
            width: 100%;
          }
        }
      }
    }
  }
`

export default hubspotForms

import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  flexboxgrid: {
    gridSize: 12, // columns
    gutterWidth: 3, // rem
    outerMargin: 1.5, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 78, // rem
      md: 102.4, // rem
      lg: 119, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48.75, // em
      md: 64, // em
      lg: 77.5, // em
    },
  },
  breakpoints: ['48.75em', '64em', '77.5em'], //make sure to keep the same values as above
  fonts: {
    body: 'PP Telegraf, sans-serif',
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48],
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semi: 600,
    bold: 700,
    ultraBold: 800,
  },
  colors: {
    white: '#FFFFFF',
    dark: '#0E0E0F',
    darkBlue: '#706BF4',
    lightBlue: '#EFF5FD',
    darkBlueShade: '#534EDD',
    blue: '#6B9BF4',
    red: '#F4726B',
    lightRed: '#F4726B1A',
    purple: '#C395F2',
    pink: '#FFBCDC',
    yellow: '#F9DE8F',
    green: '#54BFB8',
    greenLite: '#E9F7F6',
    violetLight: '#F0F0FF',
    grey50: '#878787',
    grey30: '#B7B7B7',
    grey20: '#CFCFCF',
    grey10: '#E7E7E7',
    grey5: '#F3F3F3',
  },
  space: [0, 4, 8, 12, 16, 24, 32, 40, 48, 56, 64],
  radii: {
    default: 4,
    small: 8,
    regular: 16,
    medium: 24,
    large: 32,
    circle: 99999,
  },
  shadows: {
    leftDark: '-8px 8px 0px #000',
    leftDarkBlue: '-8px 8px 0px #706BF4',
    destinationCard: '0 8px 0px #0E0E0F',
    card: '0 6px 0px #0E0E0F',
    cardDarkBlue: '0 6px 0px #706BF4',
  },
}

export default theme

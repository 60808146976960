import styled from 'styled-components'
import { compose, margin, color, system, typography, variant } from 'styled-system'

import { TextProps } from './Text'

export const StyledText = styled.p<TextProps>`
  line-height: 1.5;
  transition: all 0.25s ease-in-out;
  text-underline-offset: 2px;

  ${system({
    textTransform: true,
    textDecoration: true,
    whiteSpace: true,
  })}

  ${variant({
    prop: 'type',
    variants: {
      large: {
        fontSize: ['18px', '20px'],
        lineHeight: '1.4em',
      },
      regular: {
        fontSize: '16px',
        lineHeight: '1.5em',
      },
      small: {
        fontSize: '14px',
        lineHeight: '1.2em',
      },
      xsmall: {
        fontSize: '12px',
        lineHeight: '2em',
      },
    },
  })}
  
  ${compose(typography, margin, color)}
`

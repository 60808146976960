import React from 'react'

function CheckLarge(props: React.SVGProps<SVGSVGElement> & { color?: string; size?: number }): JSX.Element {
  const { color, style, size, ...otherProps } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size ?? 24}
      height={size ?? 24}
      viewBox={'0 0 24 24'}
      fill='none'
      {...otherProps}
    >
      <path
        fill='#0E0E0F'
        fillRule='evenodd'
        d='m6.011 9.088 3.823 4.215 8.608-8.607 1-1 4.505 4.483-1 1L9.59 22.535l-1 1L.2 14.9l1-1 3.81-3.812z'
        clipRule='evenodd'
      />
      <path
        fill='#706BF4'
        fillRule='evenodd'
        d='m5.976 10.538 3.823 4.215 9.644-9.645 3.087 3.073L9.6 21.11 2.604 13.91z'
        clipRule='evenodd'
      />
    </svg>
  )
}
export default CheckLarge

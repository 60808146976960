import * as React from 'react'
import theme from '../../../theme'

function Twitter(props: React.SVGProps<SVGSVGElement> & { color?: string; size?: number }): JSX.Element {
  const { color, style, size, ...otherProps } = props
  return (
    <svg
      width={size ? size : 24}
      height={size ? size : 24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <path
        d='M23.82 6.44a.5.5 0 00-.2-.87l-.79-.2a.5.5 0 01-.33-.7l.44-.89a.5.5 0 00-.58-.7l-2 .56a.5.5 0 01-.44-.08 5 5 0 00-8 4v.36a.25.25 0 01-.22.25c-2.81.33-5.5-1.1-8.4-4.44a.51.51 0 00-.51-.15.5.5 0 00-.29.42 7.58 7.58 0 00.46 4.92.25.25 0 01-.26.36l-1.12-.22a.5.5 0 00-.57.59 5.15 5.15 0 002.37 3.78.25.25 0 010 .45l-.53.21a.5.5 0 00-.26.69 4.36 4.36 0 003.2 2.48.25.25 0 010 .47c-1.36.562-2.819.845-4.29.83a.51.51 0 10-.2 1 20.062 20.062 0 008.14 1.93 12.58 12.58 0 007-2A12.5 12.5 0 0022 9.06v-.87a.501.501 0 01.18-.38l1.64-1.37z'
        fill={color ? theme.colors[color] || color : 'currentColor'}
      />
    </svg>
  )
}
export default Twitter

import React, { MouseEvent } from 'react'
// Types
import { MarginProps } from 'styled-system'
import { ButtonType } from '../../types/buttons'
import { ExternalLinkProps, PageInfoLinkProps } from '../../types/pages'
// Utils
import { pushEvent } from '../../utils/events'
import { scrollToAnchor } from './Button.utils'
// Components
import Link from '../Link'
import { StyledButton } from './Button.styled'
import Loader from './Loader'
import { PostLinkProps } from '../../types/blog'
import { ModalProps } from '../../types/blocks'
import { useModal } from '../../contexts/ModalContext'

export type ButtonProps = MarginProps & {
  id?: string
  label?: string
  buttonType: ButtonType
  datalayerEvent?: string
  datalayerCustomParam?: string
  size?: 'lg' | 'sm'
  disabled?: boolean
  isLoading?: boolean
  onClick?: () => void
  link?: PageInfoLinkProps | ExternalLinkProps | PostLinkProps | ModalProps
  scrollTo?: string
  fluid?: boolean
  buttonId?: string
  color?: string
}

const Button: React.FC<ButtonProps> = (props) => {
  //Destructure `id to avoid to have it in otherProps
  const {
    id,
    buttonId,
    children,
    label,
    link,
    isLoading,
    fluid,
    size,
    buttonType,
    scrollTo,
    onClick,
    datalayerEvent,
    datalayerCustomParam,
    ...otherProps
  } = props

  const { openModal } = useModal()

  if (link && 'form' in link) {
    return (
      <StyledButton
        buttonType={buttonType}
        isLoading={isLoading}
        fluid={fluid}
        size={size}
        {...otherProps}
        onClick={() => {
          pushEvent(datalayerEvent, datalayerCustomParam)
          openModal(link)
        }}
      >
        <span>{children || label}</span>
      </StyledButton>
    )
  }

  return (
    <Link link={link} fluid={fluid} id={link ? buttonId : undefined}>
      <StyledButton
        buttonType={buttonType}
        isLoading={isLoading}
        fluid={fluid}
        size={size}
        {...otherProps}
        onClick={
          !!scrollTo
            ? () => {
                pushEvent(datalayerEvent, datalayerCustomParam)
                scrollToAnchor(scrollTo)
              }
            : () => {
                pushEvent(datalayerEvent, datalayerCustomParam)
                if (onClick) {
                  onClick()
                }
              }
        }
        id={!link ? buttonId : undefined}
      >
        {isLoading ? (
          <Loader size={size} buttonType={buttonType} />
        ) : (
          <>
            <span>{children || label}</span>
          </>
        )}
      </StyledButton>
    </Link>
  )
}

Button.defaultProps = {
  buttonType: 'primary',
  size: 'lg',
  isLoading: false,
  disabled: false,
  onClick: undefined,
}

export default Button

import { createContext } from 'react'

interface LocaleContextProps {
  locale: string | null
  allSlugLocales: Array<{
    locale: string
    value: string
  }>
  id: string | null
}

const LocaleContext = createContext<LocaleContextProps>({} as LocaleContextProps)

export default LocaleContext

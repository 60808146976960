import styled from 'styled-components'
import { mediaQueries } from '../../../theme'

export const TypeformFormWrapper = styled.div`
  .typeform-form-container {
    aspect-ratio: 1/1.25;
  }

  ${mediaQueries.greaterThan('md')`
    &[data-format="vertical"] .typeform-form-container {
      aspect-ratio: 1/1.3;
    }
    &[data-format="horizontal"] .typeform-form-container {
      aspect-ratio: 1 / 0.7;
    }
  `}
`

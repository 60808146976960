import styled from 'styled-components'
import { rgba } from 'polished'
import { variant, margin } from 'styled-system'

import { ButtonProps } from './Button'

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  font-family: inherit;
  border-style: solid;
  border-width: 1px;
  outline: none;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  ${margin}

  &[disabled] {
    cursor: not-allowed;
  }

  //&:hover {
  //  .btn-icon {
  //    transform: translateX(4px);
  //  }
  //}

  ${({ buttonType }) =>
    variant({
      prop: 'size',
      variants: {
        lg: {
          fontSize: 2,
          fontWeight: 'ultraBold',
          minHeight: !buttonType.startsWith('text') ? '48px' : 'unset',
          paddingX: !buttonType.startsWith('text') ? '24px' : 0,
          paddingY: !buttonType.startsWith('text') ? '14px' : 1,
        },
        sm: {
          fontSize: 2,
          fontWeight: 'ultraBold',
          minHeight: !buttonType.startsWith('text') ? '35px' : 'unset',
          paddingX: !buttonType.startsWith('text') ? '16px' : 0,
          paddingY: !buttonType.startsWith('text') ? '6px' : 1,
        },
      },
    })}

  ${({ theme, isLoading, fluid, color }) =>
    variant({
      prop: 'buttonType',
      variants: {
        primary: {
          width: fluid ? '100%' : 'auto',
          color: 'white',
          backgroundColor: 'dark',
          borderColor: 'dark',
          borderRadius: 'circle',
          '&:hover:not([disabled])': {
            backgroundColor: '#25242B',
            borderColor: '#25242B',
          },
          '&[disabled]': {
            span: {
              opacity: 0.4,
            },
          },
        },
        white: {
          width: fluid ? '100%' : 'auto',
          color: 'dark',
          backgroundColor: 'white',
          borderColor: 'white',
          borderRadius: 'circle',
          '&:hover:not([disabled])': {
            backgroundColor: 'dark',
            borderColor: 'dark',
            color: 'white',
          },
          '&[disabled]': {
            span: {
              opacity: 0.4,
            },
          },
        },
        secondary: {
          width: fluid ? '100%' : 'auto',
          color: 'white',
          backgroundColor: 'darkBlue',
          borderColor: 'darkBlue',
          borderRadius: 'circle',
          '&:hover:not([disabled])': {
            backgroundColor: 'darkBlueShade',
            borderColor: 'darkBlueShade',
          },
          '&[disabled]': {
            span: {
              opacity: 0.4,
            },
          },
        },
        outline: {
          width: fluid ? '100%' : 'auto',
          color: 'dark',
          bg: 'transparent',
          borderColor: rgba(theme.colors.dark, 0.4),
          borderRadius: 'circle',
          '&:hover:not([disabled])': {
            borderColor: 'dark',
          },
          '&[disabled]': {
            opacity: isLoading ? 1 : 0.4,
          },
        },
        outlineWhite: {
          width: fluid ? '100%' : 'auto',
          color: 'white',
          bg: 'transparent',
          borderColor: rgba(theme.colors.white, 0.4),
          borderRadius: 'circle',
          '&:hover:not([disabled])': {
            borderColor: 'white',
          },
          '&[disabled]': {
            opacity: isLoading ? 1 : 0.4,
          },
        },
        text: {
          position: 'relative',
          color: color || 'dark',
          bg: 'transparent',
          border: 'none',
          '&::before, &::after': {
            content: JSON.stringify(''),
            position: 'absolute',
            width: '100%',
            height: '1px',
            borderRadius: '4px',
            bg: color || 'dark',
            bottom: 0,
            left: 0,
          },
          '&::before': {
            opacity: 0.3,
          },
          '&::after': {
            width: '0%',
            transition: 'width 0.25s ease-in-out',
          },
          '&:hover:not([disabled])': {
            '&::after': {
              width: '100%',
            },
          },
          '&[disabled]': {
            span: {
              opacity: 0.4,
            },
          },
        },
      },
    })}
`

import styled from 'styled-components'
import { LoaderProps } from './Loader'
import { variant } from 'styled-system'

export const StyledLoader = styled.svg<LoaderProps>`
  display: block;
  color: currentColor;

  ${variant({
    prop: 'size',
    variants: {
      lg: {
        width: '18px',
        height: '18px',
      },
      sm: {
        width: '16px',
        height: '16px',
      },
    },
  })}
`

export const StyledLoaderGradient = styled.div`
  width: 100%;
  height: 100%;
  background: conic-gradient(transparent, currentColor);
`

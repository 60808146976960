import React from 'react'
import theme from '../../../theme'

const ArrowRight: React.FC<React.SVGProps<SVGSVGElement> & { color?: string; size?: number }> = (props) => {
  const { color = 'dark', style, size, ...otherProps } = props

  return (
    <svg
      width={size ? size : 17}
      height={size ? size : 17}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.937 1.937a.75.75 0 10-.991 1.126l9.148 8.054H2.5a.75.75 0 000 1.5h17.596l-9.15 8.055a.75.75 0 10.991 1.125l9.915-8.727a1.602 1.602 0 00.54-1.1.766.766 0 000-.208 1.602 1.602 0 00-.54-1.095l-9.915-8.73z'
        fill={color ? theme.colors[color] || color : 'currentColor'}
      />
    </svg>
  )
}

export default ArrowRight

import React from 'react'
import theme from '../../../theme'
import { Box } from '../../Box'

interface TemplateTagProps {
  label: string
  color?: 'blue' | 'green' | 'red'
}

const TemplateTag: React.FC<TemplateTagProps> = (props) => {
  const { label, color } = props

  return (
    <Box
      display={'inline-block'}
      background={
        color == 'blue'
          ? theme.colors.lightBlue
          : color === 'green'
          ? theme.colors.greenLite
          : color === 'red'
          ? theme.colors.lightRed
          : ''
      }
      borderStyle={'solid'}
      borderWidth={1}
      borderColor={!color ? 'grey30' : 'transparent'}
      py={1}
      px={2}
      color={
        color == 'blue'
          ? theme.colors.blue
          : color === 'green'
          ? theme.colors.green
          : color === 'red'
          ? theme.colors.red
          : 'black'
      }
      fontSize={12}
      fontWeight={700}
      borderRadius={2}
    >
      {label}
    </Box>
  )
}

export default TemplateTag

import React from 'react'
import theme from '../../../theme'

function Check(props: React.SVGProps<SVGSVGElement> & { color?: string; size?: number }): JSX.Element {
  const { color, style, size, ...otherProps } = props
  return (
    <svg
      width={size ? size : 22}
      height={size ? size : 22}
      viewBox={'0 0 22 22'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <path
        d='M18.333 5.5L8.25 15.583 3.667 11'
        stroke={color ? theme.colors[color] || color : 'currentColor'}
        strokeWidth={1.75}
      />
    </svg>
  )
}
export default Check

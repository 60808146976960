import React, { useMemo } from 'react'
// Types
import { ButtonType } from '../../../types/buttons'
// Components
import { StyledLoader, StyledLoaderGradient } from './Loader.styled'

export type LoaderProps = {
  size?: 'lg' | 'sm'
  buttonType?: ButtonType
}

const Loader: React.FC<LoaderProps> = (props) => {
  const idRef = useMemo<string>(() => (Math.random() + 1).toString(36).substring(7), [])

  return (
    <StyledLoader {...props} width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <clipPath id={`loaderPath-${idRef}`}>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M5.801 3.791A14.5 14.5 0 0115 .5a2.5 2.5 0 010 5 9.5 9.5 0 106.718 2.782 2.5 2.5 0 013.535-3.535 14.5 14.5 0 11-19.452-.956z'
          />
        </clipPath>
        <foreignObject width='30' height='30' clipPath={`url(#loaderPath-${idRef})`}>
          <StyledLoaderGradient />
        </foreignObject>
        <animateTransform
          attributeName='transform'
          type='rotate'
          from='0 15 15'
          to='360 15 15'
          dur='0.9s'
          repeatCount='indefinite'
        />
      </g>
    </StyledLoader>
  )
}
export default Loader

import styled, { css } from 'styled-components'
import {
  compose,
  position,
  PositionProps,
  space,
  SpaceProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  background,
  BackgroundProps,
  border,
  BorderProps,
  shadow,
  ShadowProps,
  system,
  ResponsiveValue,
  Theme,
  RequiredTheme,
  ThemeValue,
} from 'styled-system'

interface GapSpaceProps<ThemeType extends Theme = RequiredTheme, TVal = ThemeValue<'space', ThemeType>> {
  gap?: ResponsiveValue<TVal, ThemeType> | undefined
  rowGap?: ResponsiveValue<TVal, ThemeType> | undefined
  columnGap?: ResponsiveValue<TVal, ThemeType> | undefined
}

export type FlexProps = PositionProps &
  SpaceProps &
  ColorProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  ShadowProps &
  GapSpaceProps & {
    transition?: string
    clickable?: boolean
  }

const Flex = styled.div<FlexProps>`
  ${compose(position, space, color, layout, flexbox, background, border, shadow)}

  ${system({
    gap: {
      property: 'gap',
      scale: 'space',
    },
    rowGap: {
      property: 'rowGap',
      scale: 'space',
    },
    columnGap: {
      property: 'columnGap',
      scale: 'space',
    },
  })}

  ${({ transition }) =>
    transition &&
    css`
      transition: ${transition};
    `}

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`

Flex.defaultProps = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}

export default Flex

import React from 'react'
import theme from '../../../theme'

function ChevronUp(props: React.SVGProps<SVGSVGElement> & { color?: string; size?: number }): JSX.Element {
  const { color, style, size, ...otherProps } = props
  return (
    <svg
      width={size ? size : 18}
      height={size ? size : 18}
      viewBox={'0 0 18 18'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <path
        d='M16.5 13.647l-7.256-8.2a.326.326 0 00-.489 0l-7.255 8.2'
        stroke={color ? theme.colors[color] || color : 'currentColor'}
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default ChevronUp

import React from 'react'
//Types
import { ExternalLinkProps, PageInfoLinkProps, isInternalLink } from '../../types/pages'
import { useLocale } from '../../hooks/useLocale'
import { GatsbyLink } from './Link.styled'
import { PostLinkProps } from '../../types/blog'

interface LinkProps {
  params?: string
  href?: string
  link?: PageInfoLinkProps | ExternalLinkProps | PostLinkProps
  fluid?: boolean
  target?: string
  rel?: string
}

const Link: React.FC<LinkProps & React.StyleHTMLAttributes<any>> = (props) => {
  const { link, params, href, children, style, fluid, ...otherProps } = props
  const { localeSlug } = useLocale()

  if (link) {
    if (isInternalLink(link)) {
      return (
        <GatsbyLink
          to={link.slug === '/' ? `/${localeSlug}${params ?? ''}` : `/${localeSlug}${link.slug}${params ?? ''}`}
          style={{ display: fluid ? 'block' : undefined, ...style }}
          {...otherProps}
        >
          {children}
        </GatsbyLink>
      )
    } else {
      return (
        <a
          href={link.url}
          target={link.openNewTab ? '_blank' : undefined}
          rel={link.openNewTab ? 'noreferrer noopener' : undefined}
          style={{ display: fluid ? 'block' : undefined, ...style }}
        >
          {children}
        </a>
      )
    }
  } else if (href) {
    return (
      <a href={href} style={{ display: fluid ? 'block' : undefined, ...style }} {...otherProps}>
        {children}
      </a>
    )
  } else {
    return <>{children}</>
  }
}

export default Link

import styled from 'styled-components'
import { variant, compose, margin, color, typography, system } from 'styled-system'

import { HeadingProps } from './Heading'

export const StyledHeading = styled.h1<HeadingProps>`
  ${{
    fontFamily: 'PP Telegraf, sans-serif',
  }}

  ${system({
    whiteSpace: true,
  })}

  ${variant({
    prop: 'type',
    variants: {
      display: {
        fontSize: ['48px', '64px', '64px'],
        lineHeight: '1em',
        fontWeight: 'ultraBold',
        color: 'dark',
      },
      heading1: {
        fontSize: ['32px', '40px', '48px'],
        lineHeight: '1em',
        fontWeight: 'ultraBold',
        color: 'dark',
      },
      heading2: {
        fontSize: ['24px', '32px', '32px'],
        lineHeight: '1.2em',
        fontWeight: 'ultraBold',
        color: 'dark',
      },
      heading3: {
        fontSize: ['22px', '28px', '28px'],
        lineHeight: '1.2em',
        fontWeight: 'ultraBold',
        color: 'dark',
      },
      heading4: {
        fontSize: ['18px', '22px', '22px'],
        lineHeight: '1.3em',
        fontWeight: 'ultraBold',
        color: 'dark',
      },
      heading5: {
        fontSize: '18px',
        lineHeight: '1.2em',
        fontWeight: 'ultraBold',
        color: 'dark',
      },
      heading6: {
        fontSize: '16px',
        lineHeight: '1.2em',
        fontWeight: 'ultraBold',
        color: 'dark',
      },
    },
  })}
  ${compose(typography, margin, color)};
`

import { useCallback, useContext } from 'react'
import LocaleContext from '../contexts/LocaleContext'

export const useLocale = () => {
  const { locale, id, allSlugLocales } = useContext(LocaleContext)

  const localeSlug = locale === 'en' ? '' : `${locale}/`

  const getSlugForLocale = useCallback(
    (locale: string) => {
      if (allSlugLocales) {
        const slugForLocale = allSlugLocales.find((slugLocale) => slugLocale.locale === locale)
        if (slugForLocale) {
          if (slugForLocale.value === '/') {
            return locale === 'en' ? `/` : `/${locale}/`
          }
          return locale === 'en' ? `/${slugForLocale.value}` : `/${locale}/${slugForLocale.value}`
        } else {
          return localeSlug
        }
      } else {
        return '/'
      }
    },
    [allSlugLocales]
  )

  return { locale, id, localeSlug, getSlugForLocale }
}

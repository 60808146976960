import Check from './Check'
import CrossLarge from './CrossLarge'
import ChevronDown from './ChevronDown'
import ChevronUp from './ChevronUp'
import Minus from './Minus'
import Plus from './Plus'
import Linkedin from './Linkedin'
import Twitter from './Twitter'
import Facebook from './Facebook'
import Menu from './Menu'
import Close from './Close'
import Search from './Search'
import ArrowRight from './ArrowRight'
import ArrowLeft from './ArrowLeft'
import PlayButton from './PlayButton'
import CheckLarge from './CheckLarge'

const Icons = {
  ArrowRight,
  ArrowLeft,
  CrossLarge,
  Check,
  CheckLarge,
  ChevronDown,
  ChevronUp,
  Minus,
  Plus,
  Linkedin,
  Twitter,
  Facebook,
  Menu,
  Close,
  Search,
  PlayButton,
}

export default Icons

import * as React from 'react'
import theme from '../../../theme'
function Linkedin(props: React.SVGProps<SVGSVGElement> & { color?: string; size?: number }): JSX.Element {
  const { color, style, size, ...otherProps } = props
  return (
    <svg
      width={size ? size : 24}
      height={size ? size : 24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.747 19.747h-3.262V14.64c0-1.217-.022-2.783-1.696-2.783-1.697 0-1.956 1.327-1.956 2.696v5.194h-3.26v-10.5H12.7v1.435h.045c.436-.825 1.5-1.695 3.088-1.695 3.305 0 3.914 2.173 3.914 5.002v5.758zM5.894 7.814a1.893 1.893 0 11-.004-3.786 1.893 1.893 0 01.004 3.786zM4.258 19.746h3.268V9.248H4.258v10.498zM21.372 1H2.622C1.727 1 1 1.71 1 2.586v18.826C1 22.29 1.727 23 2.622 23h18.75c.897 0 1.628-.711 1.628-1.588V2.586C23 1.71 22.27 1 21.372 1z'
        fill={color ? theme.colors[color] || color : 'currentColor'}
      />
    </svg>
  )
}
export default Linkedin

import React from 'react'
import theme from '../../../theme'

const ArrowLeft: React.FC<React.SVGProps<SVGSVGElement> & { color?: string; size?: number }> = (props) => {
  const { color = 'dark', style, size, ...otherProps } = props

  return (
    <svg
      width={size ? size : 17}
      height={size ? size : 17}
      viewBox={'0 0 24 24'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.203 3.063a.75.75 0 00-.992-1.126l-9.914 8.728h-.001a1.601 1.601 0 00-.539 1.099.758.758 0 000 .208 1.601 1.601 0 00.539 1.096l9.915 8.73a.75.75 0 10.992-1.126l-9.149-8.055h17.594a.75.75 0 000-1.5H4.053l9.15-8.054z'
        fill={color ? theme.colors[color] || color : 'currentColor'}
      />
    </svg>
  )
}

export default ArrowLeft

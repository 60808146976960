import React, { useMemo } from 'react'
import { TypeformFormProps } from '../../../types/blocks'
import { Widget } from '@typeform/embed-react'
import { TypeformFormWrapper } from './TypeformForm.styled'

const TypeformForm = ({ typeform, format }: TypeformFormProps & { format: 'horizontal' | 'vertical' }) => {
  const formId = useMemo(() => {
    const url = typeform.split('/to/')
    if (url[1]) {
      return url[1]
    }
    return null
  }, [typeform])

  if (formId) {
    return (
      <TypeformFormWrapper data-format={format}>
        <Widget id={formId} className='typeform-form-container' />
      </TypeformFormWrapper>
    )
  }
  return null
}

export default TypeformForm

import { PostLinkProps } from './blog'

export interface PublicationProps {
  publishedAt: Date
  updatedAt: Date
  firstPublishedAt?: Date
}

export interface PublicationFormattedProps {
  createdAt: string
  firstPublishedAt: string
  updatedAt: string
}

export interface SEOProps {
  title: string
  description: string
  image: {
    url: string
    width: number
    height: number
  }
  twitterCard: string
}

interface PageInfoObject {
  id: string
  name: string
  slug: string
  canonicalUrl: string
  hidePrefooter: boolean
  seo: SEOProps
  preventIndexing: boolean
}

// Used for page templates
export type PageInfoProps = Pick<
  PageInfoObject,
  'slug' | 'canonicalUrl' | 'name' | 'seo' | 'hidePrefooter' | 'preventIndexing'
>
// Used for links
export type PageInfoLinkProps = Pick<PageInfoObject, 'id' | 'name' | 'slug'>

export interface ExternalLinkProps {
  id: string
  name: string
  url: string
  openNewTab: boolean
}

export interface PageContextProps {
  id: string
  locale: string
  slugLocales: Array<{
    locale: string
    value: string
  }>
}

export interface PaginationContextProps {
  limit: number
  skip: number
  totalPages: number
  currentPage: number
  previousPagePath: string
  nextPagePath: string
}
export type PaginationProps = Pick<
  PaginationContextProps,
  'totalPages' | 'currentPage' | 'previousPagePath' | 'nextPagePath'
>

export function isInternalLink(
  link: PageInfoLinkProps | ExternalLinkProps | PostLinkProps
): link is PageInfoLinkProps | PostLinkProps {
  return 'slug' in link
}
